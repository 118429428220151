<nb-layout>
    <nb-layout-header class="image-container">
        <div class="p-2">
            <a class="text-white" href="{{footerLink}}" target="_blank">{{ footerName }}</a>
        </div>
        <div class="ml-auto p-2">
            <span class="text-white">Language: </span>
            <nb-select [selected]="currentLang" (selectedChange)="changeLang($event)" status="primary">
                <nb-option *ngFor="let lang of langs" [value]="lang.code"> {{ lang.name }}</nb-option>
            </nb-select>
        </div>
    </nb-layout-header>
    <nb-layout-column class="image-container">
        <nb-card class="trans-background">
            <nb-card-body class="trans-background flex justify-content-center">
                <div class="row">
                    <div class="col text-center w-100">
                        <img class="mcp-logo p-4" src = "{{ logo_img }}" alt="Logo"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col text-center w-100">
                        <p class="title-font text-white p-3 w-100">{{ serviceProviderName }}</p>
                        <p class="subtitle-font text-white w-100">{{ 'landing.title.serviceName' | translate }}</p>
                        <h5 class="description-font p-1 text-white w-100">{{environmentName}} {{ 'landing.title.environmentName' | translate }}</h5>
                    </div>
                </div>
                <div class="row justify-content-center p-4">
                    <div class="buttons-row">
                        <button status="primary" nbButton type="button" (click)="logIn()">{{ 'landing.button.login' | translate }}</button>
                        <button status="success" nbButton type="button" (click)="createRegisterDialog()">{{ 'landing.button.register' | translate }}</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col text-center p-2">
                        <button class="text-white" nbButton type="button" (click)="createProcessDialog()" size="small" outline>{{ 'landing.button.registerInfo' | translate }}</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col text-center p-4">
                        <p class="description-font font-weight-bold text-white">{{ 'landing.footer.shortIntro' | translate }}
                            <a class="text-white" href="https://maritimeconnectivity.net/mcp-documents/#MIR" target="_blank">{{ 'landing.footer.mirName' | translate }}</a>{{ 'landing.footer.namesConnector' | translate }}
                            <a class="text-white" href="https://maritimeconnectivity.net/mcp-documents/#MSR" target="_blank">{{ 'landing.footer.msrName' | translate }}</a></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col text-center p-4">
                        <p class="description-font text-white">
                            {{ 'landing.footer.disclamer' | translate }}</p>
                    </div>
                </div>
            </nb-card-body>
        </nb-card>
    </nb-layout-column>
</nb-layout>