/**
 * Maritime Connectivity Platform Identity Registry API
 * The MCP Identity Registry API can be used for managing entities in the Maritime Connectivity Platform.<br>Two versions of the API are available - one that requires authentication using OpenID Connect and one that requires authentication using a X.509 client certificate.<br>The OpenAPI descriptions for the two versions are available <a href=\"https://api.maritimeconnectivity.net/v3/api-docs/mcp-idreg-oidc\">here</a> and <a href=\"https://api-x509.maritimeconnectivity.net/v3/api-docs/mcp-idreg-x509\">here</a>.
 *
 * OpenAPI spec version: 1.2.1
 * Contact: info@maritimeconnectivity.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Certificate } from '../model/certificate';
import { CertificateRevocation } from '../model/certificateRevocation';
import { MMS } from '../model/mMS';
import { PageMMS } from '../model/pageMMS';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MmsControllerService {

    protected basePath = 'https://api.maritimeconnectivity.net';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Creates a new MMS
     * @param body 
     * @param orgMrn 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createMMS(body: MMS, orgMrn: string, observe?: 'body', reportProgress?: boolean): Observable<MMS>;
    public createMMS(body: MMS, orgMrn: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MMS>>;
    public createMMS(body: MMS, orgMrn: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MMS>>;
    public createMMS(body: MMS, orgMrn: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createMMS.');
        }

        if (orgMrn === null || orgMrn === undefined) {
            throw new Error('Required parameter orgMrn was null or undefined when calling createMMS.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MMS>('post',`${this.basePath}/oidc/api/org/${encodeURIComponent(String(orgMrn))}/mms`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Creates a new MMS
     * @param body 
     * @param orgMrn 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createMMS1(body: MMS, orgMrn: string, observe?: 'body', reportProgress?: boolean): Observable<MMS>;
    public createMMS1(body: MMS, orgMrn: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MMS>>;
    public createMMS1(body: MMS, orgMrn: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MMS>>;
    public createMMS1(body: MMS, orgMrn: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createMMS1.');
        }

        if (orgMrn === null || orgMrn === undefined) {
            throw new Error('Required parameter orgMrn was null or undefined when calling createMMS1.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MMS>('post',`${this.basePath}/x509/api/org/${encodeURIComponent(String(orgMrn))}/mms`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete an MMS identity
     * @param orgMrn 
     * @param mmsMrn 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMMS(orgMrn: string, mmsMrn: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteMMS(orgMrn: string, mmsMrn: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteMMS(orgMrn: string, mmsMrn: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteMMS(orgMrn: string, mmsMrn: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgMrn === null || orgMrn === undefined) {
            throw new Error('Required parameter orgMrn was null or undefined when calling deleteMMS.');
        }

        if (mmsMrn === null || mmsMrn === undefined) {
            throw new Error('Required parameter mmsMrn was null or undefined when calling deleteMMS.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/oidc/api/org/${encodeURIComponent(String(orgMrn))}/mms/${encodeURIComponent(String(mmsMrn))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete an MMS identity
     * @param orgMrn 
     * @param mmsMrn 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMMS1(orgMrn: string, mmsMrn: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteMMS1(orgMrn: string, mmsMrn: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteMMS1(orgMrn: string, mmsMrn: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteMMS1(orgMrn: string, mmsMrn: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgMrn === null || orgMrn === undefined) {
            throw new Error('Required parameter orgMrn was null or undefined when calling deleteMMS1.');
        }

        if (mmsMrn === null || mmsMrn === undefined) {
            throw new Error('Required parameter mmsMrn was null or undefined when calling deleteMMS1.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/x509/api/org/${encodeURIComponent(String(orgMrn))}/mms/${encodeURIComponent(String(mmsMrn))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get a specific MMS identity
     * @param orgMrn 
     * @param mmsMrn 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMMS(orgMrn: string, mmsMrn: string, observe?: 'body', reportProgress?: boolean): Observable<MMS>;
    public getMMS(orgMrn: string, mmsMrn: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MMS>>;
    public getMMS(orgMrn: string, mmsMrn: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MMS>>;
    public getMMS(orgMrn: string, mmsMrn: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgMrn === null || orgMrn === undefined) {
            throw new Error('Required parameter orgMrn was null or undefined when calling getMMS.');
        }

        if (mmsMrn === null || mmsMrn === undefined) {
            throw new Error('Required parameter mmsMrn was null or undefined when calling getMMS.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MMS>('get',`${this.basePath}/oidc/api/org/${encodeURIComponent(String(orgMrn))}/mms/${encodeURIComponent(String(mmsMrn))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get a specific MMS identity
     * @param orgMrn 
     * @param mmsMrn 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMMS1(orgMrn: string, mmsMrn: string, observe?: 'body', reportProgress?: boolean): Observable<MMS>;
    public getMMS1(orgMrn: string, mmsMrn: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MMS>>;
    public getMMS1(orgMrn: string, mmsMrn: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MMS>>;
    public getMMS1(orgMrn: string, mmsMrn: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgMrn === null || orgMrn === undefined) {
            throw new Error('Required parameter orgMrn was null or undefined when calling getMMS1.');
        }

        if (mmsMrn === null || mmsMrn === undefined) {
            throw new Error('Required parameter mmsMrn was null or undefined when calling getMMS1.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MMS>('get',`${this.basePath}/x509/api/org/${encodeURIComponent(String(orgMrn))}/mms/${encodeURIComponent(String(mmsMrn))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get the certificate of the specified MMS with the specified serial number
     * @param orgMrn 
     * @param mmsMrn 
     * @param serialNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMMSCert1(orgMrn: string, mmsMrn: string, serialNumber: number, observe?: 'body', reportProgress?: boolean): Observable<Certificate>;
    public getMMSCert1(orgMrn: string, mmsMrn: string, serialNumber: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Certificate>>;
    public getMMSCert1(orgMrn: string, mmsMrn: string, serialNumber: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Certificate>>;
    public getMMSCert1(orgMrn: string, mmsMrn: string, serialNumber: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgMrn === null || orgMrn === undefined) {
            throw new Error('Required parameter orgMrn was null or undefined when calling getMMSCert.');
        }

        if (mmsMrn === null || mmsMrn === undefined) {
            throw new Error('Required parameter mmsMrn was null or undefined when calling getMMSCert.');
        }

        if (serialNumber === null || serialNumber === undefined) {
            throw new Error('Required parameter serialNumber was null or undefined when calling getMMSCert.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Certificate>('get',`${this.basePath}/x509/api/org/${encodeURIComponent(String(orgMrn))}/mms/${encodeURIComponent(String(mmsMrn))}/certificate/${encodeURIComponent(String(serialNumber))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get the certificate of the specified MMS with the specified serial number
     * @param orgMrn 
     * @param mmsMrn 
     * @param serialNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMMSCert(orgMrn: string, mmsMrn: string, serialNumber: number, observe?: 'body', reportProgress?: boolean): Observable<Certificate>;
    public getMMSCert(orgMrn: string, mmsMrn: string, serialNumber: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Certificate>>;
    public getMMSCert(orgMrn: string, mmsMrn: string, serialNumber: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Certificate>>;
    public getMMSCert(orgMrn: string, mmsMrn: string, serialNumber: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgMrn === null || orgMrn === undefined) {
            throw new Error('Required parameter orgMrn was null or undefined when calling getMMSCert1.');
        }

        if (mmsMrn === null || mmsMrn === undefined) {
            throw new Error('Required parameter mmsMrn was null or undefined when calling getMMSCert1.');
        }

        if (serialNumber === null || serialNumber === undefined) {
            throw new Error('Required parameter serialNumber was null or undefined when calling getMMSCert1.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Certificate>('get',`${this.basePath}/oidc/api/org/${encodeURIComponent(String(orgMrn))}/mms/${encodeURIComponent(String(mmsMrn))}/certificate/${encodeURIComponent(String(serialNumber))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get a page of MMS identities belonging to the given organization
     * @param orgMrn 
     * @param page Zero-based page index (0..N)
     * @param size The size of the page to be returned
     * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganizationMMSes(orgMrn: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageMMS>;
    public getOrganizationMMSes(orgMrn: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageMMS>>;
    public getOrganizationMMSes(orgMrn: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageMMS>>;
    public getOrganizationMMSes(orgMrn: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgMrn === null || orgMrn === undefined) {
            throw new Error('Required parameter orgMrn was null or undefined when calling getOrganizationMMSes.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PageMMS>('get',`${this.basePath}/oidc/api/org/${encodeURIComponent(String(orgMrn))}/mmses`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get a page of MMS identities belonging to the given organization
     * @param orgMrn 
     * @param page Zero-based page index (0..N)
     * @param size The size of the page to be returned
     * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganizationMMSes1(orgMrn: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageMMS>;
    public getOrganizationMMSes1(orgMrn: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageMMS>>;
    public getOrganizationMMSes1(orgMrn: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageMMS>>;
    public getOrganizationMMSes1(orgMrn: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgMrn === null || orgMrn === undefined) {
            throw new Error('Required parameter orgMrn was null or undefined when calling getOrganizationMMSes1.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PageMMS>('get',`${this.basePath}/x509/api/org/${encodeURIComponent(String(orgMrn))}/mmses`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Create a new MMS certificate using CSR
     * @param body A PEM encoded PKCS#10 CSR
     * @param orgMrn 
     * @param mmsMrn 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public newMMSCertFromCsr(body: string, orgMrn: string, mmsMrn: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public newMMSCertFromCsr(body: string, orgMrn: string, mmsMrn: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public newMMSCertFromCsr(body: string, orgMrn: string, mmsMrn: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public newMMSCertFromCsr(body: string, orgMrn: string, mmsMrn: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling newMMSCertFromCsr.');
        }

        if (orgMrn === null || orgMrn === undefined) {
            throw new Error('Required parameter orgMrn was null or undefined when calling newMMSCertFromCsr.');
        }

        if (mmsMrn === null || mmsMrn === undefined) {
            throw new Error('Required parameter mmsMrn was null or undefined when calling newMMSCertFromCsr.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pem-certificate-chain',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-pem-file',
            'text/plain'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<string>('post',`${this.basePath}/oidc/api/org/${encodeURIComponent(String(orgMrn))}/mms/${encodeURIComponent(String(mmsMrn))}/certificate/issue-new/csr`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Create a new MMS certificate using CSR
     * @param body A PEM encoded PKCS#10 CSR
     * @param orgMrn 
     * @param mmsMrn 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public newMMSCertFromCsr1(body: string, orgMrn: string, mmsMrn: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public newMMSCertFromCsr1(body: string, orgMrn: string, mmsMrn: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public newMMSCertFromCsr1(body: string, orgMrn: string, mmsMrn: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public newMMSCertFromCsr1(body: string, orgMrn: string, mmsMrn: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling newMMSCertFromCsr1.');
        }

        if (orgMrn === null || orgMrn === undefined) {
            throw new Error('Required parameter orgMrn was null or undefined when calling newMMSCertFromCsr1.');
        }

        if (mmsMrn === null || mmsMrn === undefined) {
            throw new Error('Required parameter mmsMrn was null or undefined when calling newMMSCertFromCsr1.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pem-certificate-chain',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-pem-file',
            'text/plain'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<string>('post',`${this.basePath}/x509/api/org/${encodeURIComponent(String(orgMrn))}/mms/${encodeURIComponent(String(mmsMrn))}/certificate/issue-new/csr`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Revoke the MMS certificate with the given serial number
     * @param body 
     * @param orgMrn 
     * @param mmsMrn 
     * @param certId The serial number of the certificate given in decimal
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public revokeMMSCert1(body: CertificateRevocation, orgMrn: string, mmsMrn: string, certId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public revokeMMSCert1(body: CertificateRevocation, orgMrn: string, mmsMrn: string, certId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public revokeMMSCert1(body: CertificateRevocation, orgMrn: string, mmsMrn: string, certId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public revokeMMSCert1(body: CertificateRevocation, orgMrn: string, mmsMrn: string, certId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling revokeMMSCert.');
        }

        if (orgMrn === null || orgMrn === undefined) {
            throw new Error('Required parameter orgMrn was null or undefined when calling revokeMMSCert.');
        }

        if (mmsMrn === null || mmsMrn === undefined) {
            throw new Error('Required parameter mmsMrn was null or undefined when calling revokeMMSCert.');
        }

        if (certId === null || certId === undefined) {
            throw new Error('Required parameter certId was null or undefined when calling revokeMMSCert.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/x509/api/org/${encodeURIComponent(String(orgMrn))}/mms/${encodeURIComponent(String(mmsMrn))}/certificate/${encodeURIComponent(String(certId))}/revoke`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Revoke the MMS certificate with the given serial number
     * @param body 
     * @param orgMrn 
     * @param mmsMrn 
     * @param certId The serial number of the certificate given in decimal
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public revokeMMSCert(body: CertificateRevocation, orgMrn: string, mmsMrn: string, certId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public revokeMMSCert(body: CertificateRevocation, orgMrn: string, mmsMrn: string, certId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public revokeMMSCert(body: CertificateRevocation, orgMrn: string, mmsMrn: string, certId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public revokeMMSCert(body: CertificateRevocation, orgMrn: string, mmsMrn: string, certId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling revokeMMSCert1.');
        }

        if (orgMrn === null || orgMrn === undefined) {
            throw new Error('Required parameter orgMrn was null or undefined when calling revokeMMSCert1.');
        }

        if (mmsMrn === null || mmsMrn === undefined) {
            throw new Error('Required parameter mmsMrn was null or undefined when calling revokeMMSCert1.');
        }

        if (certId === null || certId === undefined) {
            throw new Error('Required parameter certId was null or undefined when calling revokeMMSCert1.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/oidc/api/org/${encodeURIComponent(String(orgMrn))}/mms/${encodeURIComponent(String(mmsMrn))}/certificate/${encodeURIComponent(String(certId))}/revoke`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update an existing MMS identity
     * @param body 
     * @param orgMrn 
     * @param mmsMrn 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMMS(body: MMS, orgMrn: string, mmsMrn: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateMMS(body: MMS, orgMrn: string, mmsMrn: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateMMS(body: MMS, orgMrn: string, mmsMrn: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateMMS(body: MMS, orgMrn: string, mmsMrn: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateMMS.');
        }

        if (orgMrn === null || orgMrn === undefined) {
            throw new Error('Required parameter orgMrn was null or undefined when calling updateMMS.');
        }

        if (mmsMrn === null || mmsMrn === undefined) {
            throw new Error('Required parameter mmsMrn was null or undefined when calling updateMMS.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/oidc/api/org/${encodeURIComponent(String(orgMrn))}/mms/${encodeURIComponent(String(mmsMrn))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update an existing MMS identity
     * @param body 
     * @param orgMrn 
     * @param mmsMrn 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMMS1(body: MMS, orgMrn: string, mmsMrn: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateMMS1(body: MMS, orgMrn: string, mmsMrn: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateMMS1(body: MMS, orgMrn: string, mmsMrn: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateMMS1(body: MMS, orgMrn: string, mmsMrn: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateMMS1.');
        }

        if (orgMrn === null || orgMrn === undefined) {
            throw new Error('Required parameter orgMrn was null or undefined when calling updateMMS1.');
        }

        if (mmsMrn === null || mmsMrn === undefined) {
            throw new Error('Required parameter mmsMrn was null or undefined when calling updateMMS1.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/x509/api/org/${encodeURIComponent(String(orgMrn))}/mms/${encodeURIComponent(String(mmsMrn))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
