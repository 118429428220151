export * from './agentController.service';
import { AgentControllerService } from './agentController.service';
export * from './certificateController.service';
import { CertificateControllerService } from './certificateController.service';
export * from './deviceController.service';
import { DeviceControllerService } from './deviceController.service';
export * from './logoController.service';
import { LogoControllerService } from './logoController.service';
export * from './mmsController.service';
import { MmsControllerService } from './mmsController.service';
export * from './organizationController.service';
import { OrganizationControllerService } from './organizationController.service';
export * from './roleController.service';
import { RoleControllerService } from './roleController.service';
export * from './serviceController.service';
import { ServiceControllerService } from './serviceController.service';
export * from './userController.service';
import { UserControllerService } from './userController.service';
export * from './vesselController.service';
import { VesselControllerService } from './vesselController.service';
export * from './vesselImageController.service';
import { VesselImageControllerService } from './vesselImageController.service';
export const APIS = [AgentControllerService, CertificateControllerService, DeviceControllerService, LogoControllerService, MmsControllerService, OrganizationControllerService, RoleControllerService, ServiceControllerService, UserControllerService, VesselControllerService, VesselImageControllerService];
