<div class="p-0">
    <input #autoInput
    nbInput
    fullWidth
    type="text"
    (input)="onChange($event.target.value)"
    placeholder="Click to generate query field"
    [nbAutocomplete]="auto" />

    <nb-autocomplete #auto (selectedChange)="onSelectionChange($event)">

    <nb-option *ngFor="let option of filteredOptions$ | async" [value]="option">
    {{ option }}
    </nb-option>

    </nb-autocomplete>
</div>

