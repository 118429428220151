<div>
    <div class="image_container" *ngIf="image" [nbSpinner]="isLoading" nbSpinnerSize="large" nbSpinnerStatus="primary">
        <img *ngIf="!isEditing" class="mh-100" [attr.src]="image">
        <div *ngIf="isEditing" class="h-100 image_text" (click)="clickToRemoveImage()">
            <div class="container">
                <span class="text-white font-weight-bold">click to remove</span>
            </div>
        </div>
        <img *ngIf="isEditing" class="mh-100" [attr.src]="image">
    </div>
    
    <button *ngIf="!image" type='button' nbButton class="m-0" fullWidth disabled>No image</button>
    
    <input *ngIf="isEditing" type="file" accept="{{accept}}" (change)="changeFile($event)"/>
</div>
