<div class="steppers-container row">
    <div class="col">
        <nb-card>
          <nb-card-header>
            {{ 'registrationProcess.title' | translate }}
          </nb-card-header>
          <nb-card-body>
            <nb-stepper orientation="vertical">
              <nb-step label="First step">
                <h3>{{ 'registrationProcess.step1.title' | translate }}</h3>
                <p class="lorem">
                  {{ 'registrationProcess.step1.content' | translate }}
                </p>
                <button nbButton disabled nbStepperNext>{{ 'registrationProcess.button.prev' | translate }}</button>
                <button nbButton nbStepperNext>{{ 'registrationProcess.button.next' | translate }}</button>
              </nb-step>
              <nb-step label="Second step">
                <h3>{{ 'registrationProcess.step2.title' | translate }}</h3>
                <p class="lorem">
                  {{ 'registrationProcess.step2.content' | translate }}
                </p>
                <button nbButton nbStepperPrevious>{{ 'registrationProcess.button.prev' | translate }}</button>
                <button nbButton nbStepperNext>{{ 'registrationProcess.button.next' | translate }}</button>
              </nb-step>
              <nb-step label="Third step">
                <h3>{{ 'registrationProcess.step3.title' | translate }}</h3>
                <p class="lorem">
                  {{ 'registrationProcess.step3.content' | translate }}
                </p>
                <button nbButton nbStepperPrevious>{{ 'registrationProcess.button.prev' | translate }}</button>
                <button nbButton nbStepperNext>{{ 'registrationProcess.button.next' | translate }}</button>
              </nb-step>
              <nb-step label="Fourth step">
                <h3>{{ 'registrationProcess.step4.title' | translate }}</h3>
                <p class="lorem">
                  {{ 'registrationProcess.step4.content' | translate }}
                </p>
                <button nbButton nbStepperPrevious>{{ 'registrationProcess.button.prev' | translate }}</button>
                <button nbButton disabled nbStepperNext>{{ 'registrationProcess.button.next' | translate }}</button>
            </nb-step>
        </nb-stepper>
    </nb-card-body>
</nb-card>
</div>